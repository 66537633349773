form {
    width: 90%;
    max-width: 300px;
    background-color: #EFE6E6;
    padding: 33px 40px;
    display: flex;
    align-content: center;
    flex-direction: column;
    font-family: 'Urbanist',
        sans-serif;
    border-radius: 15px;

}

label {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Urbanist',
        sans-serif;
    color: #34435E;
    margin-bottom: 26px;
}

textarea {
    background-color: #F9FBFC;
    border: 2px, solid #202329;
    border-radius: 2px;
    box-shadow: 0px 5px 50px rgba(32, 35, 41, 0.25);
    color: #1E1E1F;
    font-size: 20px;
    text-align: center;
    padding: 12px;
    height: 70px;
    width: calc(100%-25px);
}

textarea::placeholder {
    color: #a5a5a5;
    /* font-family: 'Montserrat'; */
    font-weight: 400;
}

textarea:focus {
    outline-color: #34435E;
}

.TodoForm-buttonContainer {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.TodoForm-button {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    color: #202329;
    font-weight: 400;
    width: 120px;
    height: 48px;
    border-radius: 2px;
    border: none;
    font-family: 'Urbanist',
        sans-serif;
}

.TodoForm-button-add {
    background-color: #34435E;
    color: #EFE6E6;
    box-shadow: 0px 5px 25px hsl(0deg 0% 0% / 0.37);
    border-radius: 15px;
}

.TodoForm-button-cancel {
    background: transparent;
}