.img-error-todo {
    padding-top: 30px;
    display: flex;
    justify-content: center;
}

img {
    /* max-height: 500px; */
    height: 400px;
    /* width: 50%; */

}