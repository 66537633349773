.img-emptytodo {
    padding-top: 30px;
    display: flex;
    justify-content: center;
}

img {
    /* max-height: 500px; */
    height: 350px;
    /* width: 50%; */

}