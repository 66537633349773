body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #EFE6E6;
}

#root {
  font-family: 'Urbanist',
    sans-serif;
  color: #34435E;
  min-height: 100vh;
}