.TodoItem {
    background-color: #FAFAFA;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    box-shadow: 0px 5px 50px rgba(32, 35, 41, 0.15);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
}

.TodoItem-p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.TodoItem-p--complete {
    text-decoration: line-through;
}

.Icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    font-size: 20px;
    font-weight: bold;
}

.Icon-check {
    position: absolute;
    left: 12px;
}

.Icon-check--active {
    color: #25bd25;
}

.Icon-delete {
    position: absolute;
    right: 12px;
}

.Icon-delete:hover {
    color: red;
}