.button {
    align-items: center;
    background-color: rgb(52, 67, 94);
    box-shadow: 4.1px 8.2px 8.2px hsl(0deg 0% 0% / 0.37);
    border: none;
    border-radius: 50%;
    bottom: 24px;
    cursor: pointer;
    color: #EFE6E6;
    display: flex;
    font-size: 50px;
    padding: 15px;
    height: 75px;
    justify-content: center;
    position: fixed;
    right: 24px;
    width: 75px;
    z-index: 100;
}

.button:hover {
    box-shadow: 6.0px 12.0px 12.0px hsl(0deg 0% 0% / 0.31);
}

/* @media only screen and (max-width: 500) {
    .button {
        min-width: 64px;
    }
} */