.search {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.search__input {
    padding: 10px 30px;
    border-radius: 15px;
    font-size: 15px;
    font-family: 'Urbanist',
        sans-serif;
    font-weight: 100;
    border: none;
}